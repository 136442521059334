/* eslint-disable */
import { APIConfig, APP_CONFIG } from '@constants';
import axios from "axios";
import IsJsonString from '@helpers/TypeCheckers';
const methods = ['get', 'post', 'put', 'patch', 'del'];
let HOSTNAME = APP_CONFIG.CURRENT_HOST_NAME;
const ENDPOINTS = APIConfig.endpoints;


const redirectToLogin = () => {
  console.log('wwwwwwwwwwwwwwwwwwwwwwwwwwwwwww');
  if (window.location.pathname !== `${APP_CONFIG.BASE_URL}/`) {
    window.localStorage.clear();
    window.location.href = `${APP_CONFIG.BASE_URL}/`;
  }
};

const responseUnauthorised = (res) => {
  console.log('wwwwwwwwwwwwwwwwwwwwwwwwwwwwwww', res);
  if ((res && (res.status === 401 || res.status === 10006))) {
    redirectToLogin();
  }
  return res;
};

const errorUnauthorised = (err) => {
  console.log('wwwwwwwwwwwwwwwwwwwwwwwwwwwwwww', err);
  if (err && err.response && err.response && err.response.status === 401) {
    redirectToLogin();
    return Promise.reject(err.response.message);
  }
  return Promise.reject(err);
};

axios.interceptors.response.use(
  (res) => responseUnauthorised(res),
  (err) => errorUnauthorised(err)
);

function formatUrl(path) {
  console.log('wwwwwwwwwwwwwwwwwwwwwwwwwwwwwww', path);
  let apiPath = path;
  let apiPathArray = [];

  if (apiPath.indexOf('?') != -1) {
    apiPathArray = apiPath.split('?');
    apiPath = apiPathArray[0];
    apiPathArray.shift();
  }

  let mappedEndpoint = ENDPOINTS[apiPath];
  let mappedEndpoint2 = ENDPOINTS[apiPath];

  if (apiPath.indexOf('/') !== -1) {
    mappedEndpoint = '';
    let splitPathArray = apiPath.split('/');
    mappedEndpoint += ENDPOINTS[splitPathArray[0]] + '/';
    splitPathArray.shift();
    mappedEndpoint += splitPathArray.join('/');
  }
  let adjustedPath = " "
  const isPreLogin = (window.localStorage.getItem('userData') === null || window.localStorage.getItem('userData') === 'undefined');
  if (mappedEndpoint.indexOf('/content') !== -1) {

    HOSTNAME = APP_CONFIG.CURRENT_CONTENT_HOST_NAME;
    adjustedPath = mappedEndpoint[0] !== '/' ? HOSTNAME + '/' + mappedEndpoint : HOSTNAME + mappedEndpoint + (apiPathArray.length != 0 ? `?${apiPathArray.join('')}` : '');
    adjustedPath = adjustedPath.replace('/content', '');

  } else if (mappedEndpoint.indexOf('/userservice') !== -1) {

    HOSTNAME = APP_CONFIG.CURRENT_ACCOUNT_HOST_NAME;
    adjustedPath = mappedEndpoint[0] !== '/' ? HOSTNAME + '/' + mappedEndpoint : HOSTNAME + mappedEndpoint + (apiPathArray.length != 0 ? `?${apiPathArray.join('')}` : '');
    adjustedPath = adjustedPath.replace('/userservice', '');

  } else if (mappedEndpoint.indexOf('/tour') !== -1) {

    HOSTNAME = APP_CONFIG.CURRENT_TOURNAMENT_HOST_NAME;
    adjustedPath = mappedEndpoint[0] !== '/' ? HOSTNAME + '/' + mappedEndpoint : HOSTNAME + mappedEndpoint + (apiPathArray.length != 0 ? `?${apiPathArray.join('')}` : '');
    adjustedPath = adjustedPath.replace('/tour', '');

  } else if (mappedEndpoint.indexOf('/transaction') !== -1) {

    HOSTNAME = APP_CONFIG.CURRENT_TRANSACTION_HOST_NAME;
    adjustedPath = mappedEndpoint[0] !== '/' ? HOSTNAME + '/' + mappedEndpoint : HOSTNAME + mappedEndpoint + (apiPathArray.length != 0 ? `?${apiPathArray.join('')}` : '');
    adjustedPath = adjustedPath.replace('/transaction', '');

  } else if (mappedEndpoint.indexOf('/activity') !== -1) {

    HOSTNAME = APP_CONFIG.CURRENT_ACTIVITY_HOST_NAME;
    adjustedPath = mappedEndpoint[0] !== '/' ? HOSTNAME + '/' + mappedEndpoint : HOSTNAME + mappedEndpoint + (apiPathArray.length != 0 ? `?${apiPathArray.join('')}` : '');
    adjustedPath = adjustedPath.replace('/activity', '');

  } else if (isPreLogin) {
    console.log('isPreLogin', isPreLogin);
    adjustedPath = mappedEndpoint[0] !== '/' ? '/onmobileprelogin' + '/' + mappedEndpoint : '/onmobileprelogin' + mappedEndpoint + (apiPathArray.length != 0 ? `?${apiPathArray.join('')}` : '');
  } else {
    adjustedPath = mappedEndpoint[0] !== '/' ? '/OnMobileContent' + '/' + mappedEndpoint : '/OnMobileContent' + mappedEndpoint + (apiPathArray.length != 0 ? `?${apiPathArray.join('')}` : '');
  }

  let apiAddress = 'http://localhost:8080';
  if (window.location.hostname === 'gyanalaya.net' || window.location.hostname === 'www.gyanalaya.net') {
    apiAddress = 'https://api.gyanalaya.net';
  }
  
  
  adjustedPath = apiAddress + mappedEndpoint2;
  return adjustedPath;
}

export default class ApiClient {
  constructor(req) {
    console.log('dddddddddddddddddddddddddddddddd');
    methods.forEach(method => {
      this[method] = (
        path,
        { params, data, headers = {} } = {}
      ) => {
        return new Promise((resolve, reject) => {
          headers['Accept'] = 'application/json;charset=utf-8';
          headers['Content-Type'] = 'application/json';
          const accessToken = window.localStorage.getItem('access_token');

          if (accessToken && accessToken !== null && accessToken !== 'null') {
            headers['Authorization'] = "Bearer " + accessToken;
          }
          // if (userName && userName !== null) {
          //   headers['userName'] = `${userName}`;
          // }
          let generatedRequestHeader = {};
          let options = { headers, params }
          if (path.indexOf('download') != -1) {
            options = { headers, params, responseType: 'arraybuffer' }
          }
          if (method === 'post') {
            const postObj = data ? data : {};
            generatedRequestHeader = axios[method](formatUrl(path), postObj, options);
          } else {
            generatedRequestHeader = axios[method](formatUrl(path), options);
          }

          generatedRequestHeader.then(response => {
            if (path.indexOf('download') != -1) {
              resolve(response);
            } else if (response && response.data != '') {
              // resolve(response.data);
              const decodedResponse = response.data && typeof response.data === 'string' ? decodeURIComponent(response.data.replace(/\+/g, '%20')) : '';
              //below condition is true if the response is in url-encoded form, usually happens when it comes from node js
              if (decodedResponse !== '' && IsJsonString(decodedResponse)) {
                resolve(JSON.parse(decodedResponse));
              } else {
                resolve(response.data);
              }
            } else {
              resolve();
            }
          })
            .catch(error => {
              reject(error);
            });
        });
      };
    });
  }
  empty() { }
}
