import React, { Component, Fragment } from 'react';
import FooterComponent from '@components/common/Footer';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
    const userData = window.localStorage.getItem('userData');
    if (userData !== null) {
      // userData = (JSON.parse(atob(userData)));
    }
    return (
      <Fragment>
        {userData && <FooterComponent />}
      </Fragment>
    );
  }
}

export default Footer;
