import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';
import login from './login';
import forgetpassword from './forgetpassword';
import logoutaccount from './logout';
import contentupload from './contentupload';
import tournament from './tournament';
import dashboard from './dashboard';
import questionbank from './questionbank';
import admin from './viewas';
import battles from './battles';
import leaderboard from './leaderboard';
import appconfigupload from './appconfigupload';
import tournamentMaster from './tournamentMaster';
import kyc from './kycDetails';
import payment from './payment';

const appReducer = combineReducers({
  router: routerReducer,
  form: formReducer,
  login,
  logoutaccount,
  forgetpassword,
  contentupload,
  tournament,
  dashboard,
  questionbank,
  admin,
  battles,
  leaderboard,
  appconfigupload,
  tournamentMaster,
  kyc,
  payment
});

// Setup root reducer
const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
