export default {
  // This is ENGLISH language specific constant file
  // commons
  Dashboard: 'Dashboard',
  SOMETHING_WENT_WRONG: 'Something went wrong!',
  // Organization Structure Page
  Signin: 'Sign in',
  FreeAccess: 'Free access to our dashboard.',
  LoginErrorMsg: "Your username and password didn't match. Please try again.",
  LoginDataEmpty: 'Please enter the username and password',
  LoginInactiveUserErrorMsg: 'Failed to login as email is not verified.',
  Username: 'Username',
  Password: 'Password',
  Forgotpassword: 'Forgotten Password?',
  Noaccount: "Don't have an account yet?",
  Signup: 'Sign up',
  RESET_PASSWORD: 'Reset your password',
  EMAIL_VALIDATION1: 'Email is invalid',
  EMAIL_VALIDATION2: 'Email is Required',
  RESET_PASSWORD_MSG: "Enter your email address below, and we'll email instructions for setting a new one",
  EMAIL_ADDRESS: 'Email*',
  EMAIL_PLACEHOLDER: 'Enter your email address',
  RESET_PASSWORD_SUCCESS_MSG: 'Password reset email has been sent',
  RESET_PASSWORD_LINK_MSG: 'Reset Password from link sent to your email address.',
  LOGIN: 'Return to your login',
  EMAIL_ERROR: 'Entered email is not registered with any user',
  SUBMIT: 'Send Email',
  ERROR: 'Password reset failed. please try again',
  PASSWORD_RESET_CONFIRM: 'Confirm Reset Password',
  PASSWORDS: 'Please enter your new password',
  NEW_PASSWORD: 'Enter your new password',
  PASSWORD_INSTRUCTION1: 'Your password cant be too similar to your other personal information',
  PASSWORD_INSTRUCTION2: 'Your password must contain atleast 8 characters, atleast one special character and atleast one number',
  PASSWORD_INSTRUCTION3: 'Your password cant be commonly used password',
  PASSWORD_INSTRUCTION4: 'Your password cant be entirely numeric',
  PASSWORD_INSTRUCTION5: 'Your password cant be same as previous password',
  PASSWORD_LABEL: 'New Password',
  CONFIRM_PASSWORD_LABEL: 'Confirm New Password',
  CONFIRM_PASSWORD: 'Enter your ConfirmPassword',
  PASSWORD_VALIDATIONS: 'New Password is Required',
  CONFIRM_PASSWORD_VALIDATIONS: 'Confirm New Password is Required ',
  RESET: 'Reset Password',
  PASSWORD_VALIDATION: 'Password must contain at least 8 characters, one uppercase, one lowercase, one number and one special case character',
  NewPassword: 'New password',
  ConfirmPassword: 'Confirm new password',
  Update: 'Update',
  EmailAddress: 'Email Address',
  FirstName: 'First name',
  LastName: 'Last name',
  Overview: 'OVERVIEW',
  Settings: 'Settings',
  CurrentPasswordMsg: 'Enter current password',
  NewPasswordMsg: 'Enter new password',
  ConfirmPasswordMsg: 'Enter confirm password',
  IncorrectPassword: 'Incorrect password',
  PasswordLengthMsg: 'Password is too short - should be 8 chars minimum.',
  PasswordMatch: 'Passwords must match',
  PASSWORDTEST2: 'New Password cannot be same as old password',

  // register
  SIGN_UP: 'Sign up',
  FREE_ACCESS: 'Free access to our dashboard.',
  USERNAME: 'Username',
  EMAIL: 'Email address',
  PASSWORD: 'Password',
  REGISTRATION_SUCCESS: 'Registration successful!',
  CONFIRM_EMAIL: 'Please confirm your email address to complete the registration.',
  EMAIL_SENT: 'An email has been sent to your email address.',
  RETURN_TO_LOGIN: 'Return to your login',
  ALREADY_HAVE_ACCOUNT: 'Already have an account?',
  INVALID_CAPTCHA: 'Invalid captcha',
  INSERT_CAPTCHA: 'Insert captcha',
  USERNAME_EXIST: 'A user with that username already exists.',
  EMAIL_EXIST: 'A user with that email already exists.',
  VERIFY_FAILED: 'ACTIVATION INVALID!',
  VERIFY_FAILED_MESSAGE: 'Activation link is invalid! Please try to register again or contact customer support.',
  USER_ALREADY_REGISTERED: 'User has already registered, please login with the same credentials',
  VERIFY_SUCCESS: 'ACTIVATION SUCESSFUL!',
  VERIFY_SUCCESS_MESSAGE: 'Thank you for your email confirmation. Now you can login your account.',
  ENTER_USERNAME: 'Enter your username',
  ENTER_EMAIL: 'name@address.com',
  ENTER_PASSWORD: 'Enter your password',
  RE_ENTER_PASSWORD: 'Re-enter your password',
  PASSWORD_MATCH: 'The two password fields did not match.',
  REQUIRED: 'Required',
  INVALID_EMAIL: 'Invalid email format',
  PASSWORD_REGEX: 'Password must contain at least 8 characters, one uppercase, one lowercase, one number and one special case character',
  INVALID_NAME: 'Invalid name format',
  USERNAME_VALIDATION: 'Username is required',
  EMAIL_ADDRESS_VALIDATION: 'Email address is required',
  CAPTCHA_VALIDATION: 'Captcha is required',
  PASS_VALIDATION: 'Password is required',
  PASSWORD_VERIFY_FAILED: 'RESET PASSWORD LINK INVALID!',
  PASSWORD_VERIFY_FAILED_MESSAGE: 'Reset password link expired! Please try again.',
  REMEMBER_YOU_PASSWORD: 'Back to ',

  // Dashboard
  PROGRAM: 'Programs',
  PROCESS: 'Process',
  ADD_NEW: 'Add new',
  LOGOUT: 'Logout',
  SETTING: 'Settings',

  // Dashboard
  PROCESSING_PROGRAMS: 'PROCESSING PROGRAMS',
  TOTAL_NO_PROGRAMS: 'Total No. Programs',
  TOTAL_HOURS: 'Total hours',
  TOTAL_SPEECH_HOURS: 'TOTAL SPEECH HOURS',
  WEEKLY_USEAGE_NO_PROGRAMS_PROCESSED_PER_DAY: 'Weekly Usage (No. of programs processes per day)',
  PROCESS_LOGS: 'Process Logs',
  TASKS: 'TASKS',
  LANGUAGE: 'Language',
  TOTAL_DURATIONS: 'TOTAL DURATION (S)',
  SPEECH_DURATIONS: 'SPEECH DURATION (S)',
  STATUS: 'Status: ',
  SUBMITTED_DATE: 'SUBMITTED DATE',
  ADD_PROGRAM: 'Add program',
  VIEW_ALL_PROGRAM: 'View All Programs',
  FILTERS: 'Filters',

  // addProcess
  SubmitNewVideo: 'Submit new video for processing',
  Uploadfromcomputer: 'Upload from Computer',
  URLUpload: 'URL Upload',
  Name: 'Name',
  DesicriptiveContext: 'Descriptive name for your video. Will take upload name as default if left blank.',
  UploadVideo: 'Upload Video',
  UploadsupportFiles: 'Upload video file from file system. For a list of supported file formats see the',
  ffmpegdocumentation: 'ffmpeg documentation',
  Warning: 'Warning',
  Maximumfilesize: 'Maximum file size: ',
  filesize: '200MB',
  Maximumlength: 'Maximum length: ',
  hours: '5 hours',
  LanguageSelection: 'Language Selection',
  Pleaseselect: 'Please select language of your video.',
  StartProcess: 'Start Process',
  Youaudio: 'You can specify a name for your audio or video here',
  VideoURL: 'Video URL',
  Pleaseinput: 'Please input your youtube or mp4 video link here',
  urlPlaceholder: 'https://www.youtube.com/watch?v=BVvBa18o8Es',
  FILE_LARGE: 'File size is more than 200MB',
  MIN_LENGTH_NAME: 'Name has to have at least 3 characters',
  MAX_LENGTH_NAME: 'Name has to have less than 20 characters',
  UNSUPPORTED_FILE_FORMATES: 'Unsupported file format',

  // myProfile
  PASSWORD_REQUIREMENTS: 'Password requirements',
  TO_CREATE: 'To create a new password, ',
  FOLLOWING_REQUIREMENTS: 'you have to meet all of the following requirements:',
  MINIMUM_CHARACTER: 'Minimum 8 character',
  AT_LEAST_SPECIAL: 'At least one special character',
  AT_LEAST_NUMBER: 'At least one number',
  SAME_AS_OLD_PASSWORD: 'Can’t be the same as a previous password',
  EMAIL_UPDATE: 'This contact will be used to send you update about programs process.',
  PREV_PASSWORD_MATCH: 'Can’t be the same as a previous password',
  ENTER_PREV_PASSWORD: 'Current password correctly did not match',
  UPDATE: 'Update',

  // intermediateScreen
  WHAT_TO_DO: 'What do you want to do',
  TRANSCRIBE: 'Transcribe',
  TRANSLATE: 'Translate',
  FREE_ACCESS_SERVICE: 'Free access to our services.',

  // Speech Aligner and Text Editor
  ASC: 'Asc',
  DESC: 'Desc',
  SORT_ORDER: 'Sort order',
  FILES: 'Files',
  ERROR_MSG_PROCESS_FAILED: 'The process seems to have resulted in a problem. Kindly try again. If this issue persist, kindly contact',
  SPEECH_ALIGNER_TEXT_EDITOR: 'Speech Aligner and Text Editor',
  ALPHA: 'Alpha',

  // program form
  TOTAL_DURATION: 'Total duration',
  SPEECH_DURATION: 'Speech duration',
  // translation url
  TOOLTIP_COLOR: '#e4e9f1 !important',
  LOG_OUT: 'Log Out',
  CUSTOMER_SUPPORT: 'Customer Support',

  // programs
  ALL_PROCESS: 'ALL PROCESS LOG',
  ARABIC: 'Arabic',
  EGYPT: 'Egyptian Arabic',
  ENGLISH: 'English (Provisional)',
  SUBMITTED: 'Submitted',
  PENDING_PROCESS: 'Pending process',
  STARTED: 'Started',
  PROCESSING: 'Processing',
  PROCESS_COMPLETED: 'Completed',
  PROCESS_FAILED: 'Process failed',
  STATUS_SUBMITTED: 1,
  STATUS_PROCESSING: 2,
  STATUS_PROCESS_COMPLETED: 3,
  STATUS_PROCESS_FAILED: 4,
  STATUS_SUBMITTED_STRING: '1',
  STATUS_PROCESSING_STRING: '2',
  STATUS_PROCESS_COMPLETED_STRING: '3',
  STATUS_PROCESS_FAILED_STRING: '4',
  PENDING: 'Pending',
  DENIED: 'Denied',
  ALL: 'All',
  BADGE: 'badge-',
  // file constants
  WAV_EXT: 'WAV',
  WAV_FILE_NAME: 'WAV Audio',
  CTM_EXT: 'CTM',
  CTM_FILE_NAME: 'Edited CTM',
  SRT_EXT: 'SRT',
  SRT_FILE_NAME: 'Edited SRT',
  XML_EXT: 'XML',
  XML_FILE_NAME: 'Edited XML',
  VTT_EXT: 'VTT',
  VTT_FILE_NAME: 'Edited VTT',
  TXT_EXT: 'TXT',
  MP3_EXT: 'MP3',
  TXT_FILE_NAME: 'Edited TXT',

  SUBTITLES_IN: 'Subtitles in',
  DOWNLOAD: 'Download',

  // sort orders
  ASCENDINGORDER: 1,
  DESCENDINGORDER: 2,

  FILEEXTENSIONLISTWITHNAME: [
    {
      fileExt: 'WAV',
      fileName: 'WAV Audio',
    },
    {
      fileExt: 'CTM',
      fileName: 'Edited CTM'
    },
    {
      fileExt: 'SRT',
      fileName: 'Edited SRT'
    },
    {
      fileExt: 'XML',
      fileName: 'Edited XML'
    },
    {
      fileExt: 'VTT',
      fileName: 'Edited VTT',
    },
    {
      fileExt: 'TXT',
      fileName: 'Edited TXT'
    },
    {
      fileExt: '.',
      fileName: 'Subtitles in'
    }],

  FILES_STARTED: 'Files successfully started processing!',
  CHECK_BACK_LATER: 'We are currently processing your files. You will be notified via email once the process is completed',

  FILES_BELOW: 'Your processed files will be presented below:',
  FILES_RECEIVED: 'Your files have been received and we will soon start processing it. You will be notified via email once the process is completed',
  FILES_PROCESSED_SUCCESS: 'Files successfully processed!',
  PROCESSED_FILES_BELOW: 'You can see your processed file below:',
  SUBMITTED_DATE_TEXT: 'submittedDate',
  SAVE: 'Save',
  AUDIO: 'Audio',
  FILES_QUEUED_FOR_PROCESS: 'Files queued for process',
  TRANSALATION: 'Translation',
  SOURCE: 'Source',
  DOMAIN: 'Domain',
  TRANSLATE_TEXT: 'Translate text',
  TRANSLATION_SYSTEM: 'Translation System',
  EMPTY_INPUT: 'Empty Input',
  DESCRIPTION_REQUIRED: 'Name is Required',
  FILE_REQUIRED: 'File is Required',
  LANGUAGE_REQUIRED: 'Language is Required',
  TYPE_REQUIRED: 'Type is Required',
  URL_REQUIRED: 'URL is Required',
  UNSUPPORTED_URL: 'Provided url is not supported',
  TYPE: 'Type',
  PLEASE_SELECT_THE_MODE: 'Please select the mode',
  MAX_LENGTH: 'Must be 20 characters or less',
  MIN_LENGTH: 'Must be at least 3 characters',
  REMAINING_SUBSCRIPTION_DURATION: 'Remaining subscription duration after processing will be',
  VEDIO_LENGTH_IS_MORE_THAN_SUBSCIBED_TIME: 'Video Length is more than available subscribed time limit',
  REQUIRED_FIELD: '*',
  PACK_NAME: 'PACK_NAME',
  PACK_DESCRIPTION: 'PACK_DESCRIPTION',
  AVAILABLE_TIME: 'AVAILABLE_TIME',
  REQUESTED_TIME: 'REQUESTED_TIME',
  LICENSE_DETAILS: 'User Subscription Details',
  USER_NOT_SUBSCRIBED_TO_ANY_LICENSE: 'No Active Subscription',

  SEG: 'SEG',
  TRANSLATING: 'Translating....',

  NO_SPKR: 'Number of Speakers',
  SPKR_CONETEXT: 'Enter no of speakers.',
  SPKR_REQUIRED: 'No of Speaker required',
  INVALID_NUMBER: 'Enter only numbers',
  SUPPORT: 'Support',
  HAPPY_SCRIBE_DASHBOARD: 'DASHBOARD',
  UPLOAD_NEW_FILE: 'UPLOAD NEW FILE',
  REMAINING_CREDIT: 'Remaining Credit',
  BUY_MORE_HOURS: 'Buy More Hours',
  WORKSPACE: 'Workspace',
  CREATE_WORKSPACE: 'Create workspace',
  EXPORT_SELECTED: 'Export Selected',
  DELETE_SELECTED: 'Delete Selected',
  DUPLICATED_SELECTED: 'Duplicate Selected',
  RENAME_SELECTED: 'Rename Selected',
  UPLOAD_DATE: 'Upload Date',
  ORIGINAL_FORMAT: 'Original Format',
  FILE_NAME: 'File Name',
  ITEMS_PER_PAGE: 'Files/Folders per page',
  NEW_FOLDER: 'New Folder',
  CREATE_FOLDER: 'Create Folder',
  CANCEL: 'Cancel',
  MOVE: 'Move',
  EXPORT: 'Export',
  DELETE: 'Delete',
  DELETE_MESSAGE: 'Are you sure want to delete the file/folder?',
  DELETE_TITLE: 'Delete Item',
  MODAL_CANCEL: 'Cancel',
  FOLDER: 'Folder',
  VIDEO: 'Video',
  LENGTH: 'Length',
  PAGE: 'Page',
  ENTER_FOLDER_NAME: 'Enter folder name',
  FOLDER_NAME: 'Folder name',
  NO_RESULTS_FOUND: 'No results found',
  DELETE_MESSAGE_GLOBAL: 'Are you sure want to delete the file/folder?',
  CHOOSE_FORMAT: 'Choose the format',
  WHAT_TO_EXPORT: 'What would you like to export?',
  OPEN: 'Open',
  MOVE_SELECTED: 'Move Selected',
  FILE: 'file',
  ITEM: 'file/folder',
  ENTER_CHANGED_NAME: 'Enter changed file/folder name',
  EDIT_NAME: 'Edit name',
  RENAME_FILE: 'Rename',
  SHARE: 'SHARE',
  DOWNLOAD_CAPS: 'DOWNLOAD',
  CONVERT_TO_SUBTITLES: 'Convert to Subtitles',
  SAVING: 'Saving..',
  STARTING_TIMECODE: 'Starting Timecode',
  ALIGN_AUDIO: 'Align Audio and Text',
  TEXT_TIMECODE: 'Enter the timecode at the very start of the original file',
  TEXT_ALIGN: 'After you have edited your file, the words may no longer align with your audio or video file. By realigning your file, we will adjust the time-code of each word.',
  TEXT_AUDIO: 'In the new version generated, you will lose your highlights, comments and speaker labels.',
  SEARCH_TEXT: 'Search..',
  REMOVE_USER: 'Remove',
  REMOVE_USER_MESSAGE: 'Are you sure want to remove the user?',
  DELETE_WORKSPACE: 'Delete',
  DELETE_WORKSPACE_MESSAGE: 'Are you sure want to delete the workspace?',
  ADMINISTARTOR: 'Administartor',
  REMOVE: 'Remove',
  MEMBER: 'Member',
  NEW_WORKSPACE_TEXT: 'Because you are a Sumo-ling, you have access to our Workspaces feature.',
  NEW_WORKSPACE_TEXT_1: 'In workspaces, you can invite other users to collaborate on the same files. Nevertheless, it does not imply centralized billing. So everyone pays for their files.',
  NEW_WORKSPACE: 'New Workspace',
  NAME: 'Name',
  WORKSPACE_DASHBOARD: 'Workspace Dashboard',
  MANAGE_WORKSPACE: 'Manage Workspace',
  DETAILS: 'Details',
  ADD_MEMBER: 'Add Member',
  INVITE: 'Invite',
  LIST_OF_MEMBERS: 'List of Members',
  ACCESS_LEVEL: 'Access level',
  PENDING_INVITATIONS: 'Pending Invitations',
  DELETE_WORKSPACE_TEXT: 'Delete Workspace',
  DELETE_WORKSPACE_FILES: 'Delete workspace & files',
  EMAIL_TEXT: 'Email',
  WORKSPACES: 'Workspaces',
  BACK_TO_DASHBOARD: 'Back to dashboard',
  BACK_TO: 'Back to ',
  UPDATE_TRANSLATION: 'Updating translation to latest version',
  WELCOME: 'Welcome, sign in to continue',
  NO_ACCOUNT: "Don't have an account? ",
  LOG_IN: 'Log in',
  CREATING_ACCOUNT: 'Creating your account',
  EMAIL_ADDRESS_REQUIRED: 'Email is required',
  FULL_NAME: 'Full name',
  ENTER_FULL_NAME: 'Enter full name',
  MAX_CHAR: 'Maximum 255 characters allowed',
  ADD_TO_DICTONARY: 'Add To Dictionary',
  ADD_WORD: 'Add Word',
  CANCEL_WORD: 'Cancel Word',
  SOURCE_WORD: 'Source Word',
  REPLACE_WORD: 'Replacement Word',
  ACTION: 'Action',
  SINGLE_WORD_ERR: 'Space Not Allowed',
  ADDED_UPDATED_DATE: 'Added/Updated Date',
  REMOVE_WORD: 'Are you sure you want to remove this word?',
  REMOVE_WORDS: 'Are you sure you want to remove these words?',
  GET_LINK: 'Get Link',
  ACTIVATE_SHARING: 'Activate Sharing',
  ALLOW_EDITING: 'Allow Editing',
  COPY: 'Copy',
  END_DATE_ERROR: 'To-Date should be greater than From-Date',
  START_DATE_ERROR: 'From-Date should be lesser than To-Date',
  TOTAL_TOURNAMENTS: 'Total Tournaments',
  TOURNAMENTS_CREATED: 'Upcoming Tournaments',
  TOURNAMENTS_DRAFT: 'New Tournaments Ready',
  LIVE_TOURNAMENTS: 'Live Tournaments',
  COMPLETED_TOURNAMENTS: 'Completed Tournaments',
  INVALIDATED_TOURNAMENTS: 'Forfeited Tournaments',
  TOURNAMENTS: 'Tournaments',
  COINS: 'Coins',
  MONEY: 'Money',
  COINS_MONEY: 'Both Coins and Money',
  REG_START_DATE: 'Reg. Start: ',
  REG_END_DATE: 'Reg. End: ',
  MAX_PALYERS: 'Max Players: ',
  MIN_PLAYERS: 'Min Players: ',
  TOUR_START_DATE: 'Start Date: ',
  TOUR_END_DATE: 'End Date: ',
  TOUR_END_TIME: 'End Time:',
  MAX_TRIES: 'Max Entries:',
  WRONG_ANSWER_POINTS: 'Wrong Answer Point: ',
  SCORE_EVERY_SEC_SAVED: 'Score Per Second Saved: ',
  CORRECT_ANS_POINTS: 'Correct Answer Point: ',
  TOTAL_QTNS: 'Total Questions: ',
  TOTAL_PLAYERS: 'Total Players Joined: ',
  REWARD_TYPE: 'Reward Type: ',
  COINS_REWARD: 'Total Coins Reward: ',
  MONEY_REWARD: 'Total Cash Reward: ',
  TOTAL_REWARD: 'Total Reward: ',
  NO_TOURNAMENT_FOUND: 'No tournaments found',
  PLAYERS: 'Players: ',
  ENTRY_FEES: 'Entry Fee: ',
  Rupees: 'Rs',
  FILL_RATE: 'Fill Rate: ',
  REWARDS: 'Reward: ',
  EDIT: 'Edit',
  OK: 'OK',
  TEXT_OPTION_KEY: 'TextOptions',
  IMAGE_OPTION_KEY: 'ImageOptions',
  IMAGE_NOT_EXISTS: 'Image url is not available',
  DOT: '...',
  DOWNLOAD_SAMPLE_TEMPLATE: 'Download Sample Template',
  UPLOAD: 'Upload',
  COPYS: '&copy',
  COPYRIGHT: 'Copyright&nbsp;&nbsp; Gyanalaya, All Rights Reserved',
  ADMIN_CONSOLE: 'Admin Console',
  CONTENT_UPLOAD: 'Content Upload',
  TOURNAMENT: 'Tournaments',
  CONFIGURATION_UPLOAD: 'Configuration Upload',
  ADMIN: 'Admin',
  LOGOUTS: 'logout',
  DASH: 'Dashboard',
  STATUSES: 'status',
  ENDDATE: 'End Date',
  STARTDATE: 'Start Date',
  SORRY: 'Sorry, unable to load the page',
  SAVES: 'Save',
  CANCELS: 'Cancel',
  REFISTRATION_END_DATE: 'Registration End Date',
  REFISTRATION_END_TIME: 'Registration End Time',
  ENTRY_FEE_TYPE: 'Entry Fee Type',
  ENTRY: 'Entry Fees',
  WELCOMES: 'Welcome',
  QUESTIONS: 'Question List',
  DASH1: '-',
  DOTS: '.',
  CORRECT_ANSWER: 'Correct Answer: ',
  INFORMATION: 'Information List',
  CATEGORY: 'Category: ',
  TOPIC: 'Topic: ',
  GENRE: 'Genre: ',
  TOTAL_MONEY_REWARDS: 'Total Money Rewads:',
  TOTAL_COIN_REWARD: 'Total Coin Reward:',
  CUT_OF_TIME: 'Cut Of Time:',
  REGISTRATION_START: 'Reg. Start:',
  CORRECT_ANSWER_POINTS: 'Correct Answer Points:',
  TOTAL_MONEY: 'Total Money:',
  TOTAL_PLAYERS_COMPLETED: 'Total Players Completed:',
  UPDATED_TS: 'UpdatedTS:',
  QUESTION_LIST: 'Question',
  KEYWORDS: 'Keywords',
  VIEW: 'View',
  LEVEL: 'Level',
  ANSWER: 'Answer',
  REGION: 'Region',
  TOTAL_USED: 'Total usage',
  USERS_SHOWN: 'Total Displayed',
  USER_ANSWERED: 'Total Answered',
  USER_ANSWERED_CORRECTLY: 'Total Answered Correctly',
  NO_QUESTIONS_FOUND: 'No questions Found',
  VEIW_UPLOAD_QUESTION: 'View Question List',
  OPTIONS: 'Options',
  DISPLAYORDER: 'DisplayOrder',
  TAGS: 'Tag',
  IMAGE_QUESTION: 'ImageQs',
  VIDEO_QUESTION: 'VideoQs',
  AUDIO_QUESTION: 'AudioQs',
  QUESTIONS_TYPE: 'Question Type',
  CLEAR_FILTERS: 'Clear Filters',
  TOPIC_FILTER: 'Topic ',
  GENRE_FILTER: 'Genre',
  CATEGORY_FILTER: 'Category',
  STATUS_FILTER: 'Status',
  TOURNAMENT_USED: 'Locked',
  SEARCH: 'Search',
  REGION_FILTER: 'Region',
  DAILY: 'Daily',
  ON: 'On',
  OFF: 'Off',
  LEADERBOARD: 'Leaderboard',
  SCORE: 'Score: ',
  RANK: 'Rank: ',
  WINNING: 'Winnings: ',
  QUESTION: 'Question ',
  TIMETAKEN: 'Time taken in sec : ',
  USERANSWER: 'User answer : ',
  VIEW_AS: 'View As',
  MOBILE_NUMBER: 'Mobile Number',
  EMAIL_ID: 'Email Id',
  NO_DATA_FOUND: 'No data found',
  MOB_NO_ERROR: 'Invalid mobile number',
  EMAIL_ID_ERROR: 'Invalid email id',
  TOTAL_BATTLES: 'Total Battles',
  AVAILBALE_BATTLES: 'Available Battles',
  LIVE_BATTLES: 'Live Battles',
  COMPLETED_BATTLES: 'Completed Battles',
  FORFEITED_BATTLES: 'Forfeited Battles',
  BATTLES: 'Battles',
  ASYNC: 'Async Battle',
  LIVE: 'Live Battle',
  BOTS: 'Bots',
  FORFEIT: 'Forfeit',
  STEP_DOWN: 'Step Down',
  EXIT_TYPE: 'Exit type: ',
  BATTLE_TYPE: 'Battle type: ',
  CREATION_TYPE: 'Creation type: ',
  USER_CREATED: 'User created',
  SYSTEM_CREATED: 'System created',
  TOTAL_PLAYERS_JOINED: 'Total Players Joined: ',
  QUESTION_TYPE: 'Type',
  AMOUNT: 'Amount: ',
  DESCRIPTION: 'Description: ',
  REAL_MONEY: 'realMoney',
  TOUR_END_DATE_TEXT: 'End Date',
  TOUR_END_TIME_TEXT: 'End Time',
  FTUE_QUESTIONS: 'Ftue Questions',
  DATE: 'Date: ',
  IS_USER_ANSWERED_CORRECTLY: 'Answered Correctly',
  PAYMENT_STATUS: 'Payment Status: ',
  PAYMENT_STATUS_SUCCESS: 'Success',
  PAYMENT_STATUS_FAILED: 'Failed',
  PAYMENT_STATUS_ISSUED: 'Issued',
  PAYMENT_STATUS_PROCESSING: 'Processing',
  PAYMENT_STATUS_PROCESSED: 'Processed',
  PAYMENT_STATUS_DEFAULT: 'N/A',
  VIEW_CONFIG: 'View Config',
  COUNTRY_CODE: 'Country Code',
  DOB: 'Dob: ',
  GENDER: 'Gender: ',
  ADDRESS: 'Address: ',
  CITY: 'City: ',
  STATE: 'State: ',
  COUNTRY: 'Country: ',
  ZIPCODE: 'Zipcode: ',
  PROFILEIMG: 'ProfileImgUrl: ',
  SMSGATEWAY: 'SmsGateway: ',
  ADDBOTLIVETIMEMILSEC: 'Add BotLiveTime: ',
  ADDBOTASYNCTIMEMILSEC: 'Add BotAsyncTime: ',
  SENDEREMAIL: 'Sender Email: ',
  TOTALRECEIVER: 'Total Receivers: ',
  RECEIVER1: 'Receiver1: ',
  RECEIVER2: 'Receiver2: ',
  RECEIVER3: 'Receiver3: ',
  RECEIVER4: 'Receiver4: ',
  RECEIVER5: 'Receiver5: ',
  OTPDIGIT: 'Otp Digits: ',
  OTPTIMEOUT: 'Otp Timeout: ',
  USERACTIVETIME: 'UserActive TimeDifference: ',
  USERLOGINTIMEDIFF: 'UserLogin TimeDifferenceCheck: ',
  WINNINGSWITHDRAW: 'Winnings WithdrawLimit: ',
  REFERRALCODELENGHT: 'ReferralCode Length: ',
  LOCATION: 'Location: ',
  LOGINSENDERBONUS: 'Login SenderBonus: ',
  LOGINRECIEVERBONUS: 'Login ReceiverBonus: ',
  MINCASHDEPOSIT: 'MinCash Deposit: ',
  CASHDEPOSITSENDERBONUS: 'Cash Deposit SenderBonus: ',
  CASHDEPOSITRECEIVERBONUS: 'Cash Deposit ReceiverBonus: ',
  TOTALTOURNAMENTTOCOMPLETE: 'Total Tournaments ToCompleted: ',
  TOURCOMPLETEDRECEIVERBONUS: 'TourCompleted ReceiverBonus: ',
  TOURCOMPLETEDSENDERRBONUS: 'TourCompleted SenderBonus: ',
  EXPIRTDATE: 'Expiry Date: ',
  EACHTOURNAMENT: 'Each Tournament: ',
  DURATION: 'Reward expiry duration: ',
  DURATIONINSECS: 'Duration InSecs: ',
  VALUE: 'Value: ',
  REWARDSTYPE: 'Rewards Type: ',
  ISALWAYSAVAILABLE: 'IsAlways Available: ',
  SKIPENABLE: 'IsSkip Enabled: ',
  TOTALQUESTIONS: 'Total Questions: ',
  RIGHTANSWERREWARDS: 'Right AnswerRewards: ',
  WRONGANSWERREWARDS: 'Wrong AnswerRewards: ',
  NOOFDAYS: 'NoOfDays',
  DAY: 'Day: ',
  BACKGROUND: 'BackGround Color: ',
  SPACE_DOTS: '. ',
  PLAYERS_ANSWER: 'Player\'s Answer',
  TIME_MILSEC: ' ms',
  HEADBOT: 'APP CONFIG LIST',
  CODE: 'Country code: ',
  DAILCODE: 'Dail Code: ',
  MCC: 'MCC: ',
  MDNL: 'MDNL: ',
  MDNMAXLENGHT: 'Mnd maximun length: ',
  MDNMINLENGHT: 'Mdn minimum length:  ',
  PRIVACY: 'Privacy url: ',
  REFERAL: 'Referal enabled: ',
  SERVERTIME: 'Server time: ',
  REFRESHINTERVALSECS: 'Referesh interval secs: ',
  TNCURL: 'Tnc url: ',
  ANDROIDVERSION: 'Android version: ',
  ANDROIDFORCEUPDATE: 'Android force update: ',
  IOSVERSION: 'IOS version: ',
  IOSFORCEUPDATE: 'IOS force update: ',
  IOSDESC: 'IOS desc: ',
  CASHENABLED: 'Cash enabled: ',
  COINENABLED: 'Coin enabled: ',
  REWARDTYPE: 'Reward type: ',
  CURRENCY: 'Currency: ',
  REFUNDPOLICY: 'Refund policy: ',
  DURATION_PER_SEC: 'Reward expiry duration in secs: ',
  CLOUDFRONTURL: 'Cloud front url: ',
  FAQURL: 'FAQ URL: ',
  MADECONTEXT: 'Made content: ',
  PAYEMENTRURL: 'Payment url: ',
  s3bucketName: 's3 bucket Name: ',
  HEADHOME: 'HOME CONFIG LIST',
  SEQUENCE: 'Sequence: ',
  TOTALRECORDS: 'Total Records: ',
  TITLE: 'Title: ',
  OPTION1: 'Option View Sub Type: ',
  OPTION2: 'Option View Type: ',
  OPTION3: 'Question View Type: ',
  POSITION: 'Position: ',
  NOOFWINNER: 'No Of Winner: ',
  BACKGROUND1: 'Background: ',
  HEADSPIN: 'SPIN CONFIG LIST',
  BOTNUMBER: 'Bot number: ',
  TOTALSCORE: 'Total score: ',
  USERTOMATCH: 'User to match: ',
  HEADBOTS: 'BOT CONFIG LIST',
  ABBREVATION: 'Abbrevation: ',
  HEADAVAT: 'AVATAR UPLOAD LIST',
  IMAGEURL: 'Image url: ',
  CATEGORYNAME: 'Category name: ',
  CREDIT: 'Credit',
  DEBIT: 'Debit',
  TOTAL_TIMETAKEN: 'Time taken: ',
  SECONDS: 'secs',
  TOTAL_COINS: 'Total Coins: ',
  TOTAL_REWARD_TYPE: 'Total RewardType: ',
  TOTAL_CASH: 'Total Cash: ',
  CLIENTID: 'Client ID',
  TOTALCOINS: 'Total Coins: ',
  TOTALREWARDTYPE: 'Total Reward Type: ',
  TOTALCASH: 'Total Cash: ',
  NOODDAYS: 'No of Days: ',
  IMAGESERVERURL: 'Image server url: ',
  AMOUNTOPT: 'Amount options: ',
  CHATBOTURL: 'Chat bot url: ',
  LOGINCOINS: 'Login bonus coins: ',
  LOGINMONEY: 'Login bonus money: ',
  LOGINREWARDTYPE: 'Login bonus reward type: ',
  OTP_LENGTH: 'Otp length: ',
  AWSCONTENT_BASEURL: 'AWS content base url: ',
  COUNTRY_NAME: 'Country name: ',
  NO_OF_CHALLENGES: 'No of challenges: ',
  ANDROIDDESC: 'Android desc: ',
  TOURNAMENTMASTER: 'Tournament Master',
  PLAYERMAX: 'Maximum Players: ',
  KEYWORD: 'Keyword: ',
  LEVEL1: 'Level: ',
  NOOFQUESTION: 'No Of Question: ',
  TIMEOFANSWER: 'Time Of Answer: ',
  TYPE1: 'Type: ',
  BANNERIMAGE: 'Banner Image: ',
  TAG: 'Tags: ',
  CANCELIFNOTJOINED: 'Cancel If Not Joined: ',
  REWARDDURATION1: 'Duration of Reward Expiry: ',
  DAYSOFWEEK: 'Day of Week: ',
  MONTHOFYEAR: 'Month of Year: ',
  MONTHLY: 'Monthly: ',
  TIMELY: 'Timely: ',
  WEEKOFMONTH: 'Week of Month: ',
  WEEKLY: 'Weekly: ',
  YEARLY: 'Yearly: ',
  REGION1: 'Region: ',
  OPTIONVIEWSUBTYPE: 'Option View Sub Type: ',
  OPTIONVIEWTYPE: 'Option View Type: ',
  QUESTIONVIEWTYPE: 'Question View Type: ',
  TOTALINSTANCES: 'Total Instances: ',
  QUESTIONTYPE: 'Questions',
  PRICEDISTRIBUTION: 'Price Distribution',
  NOOFPLAYERS: 'Number of Players: ',
  ACTIVE: 'Active: ',
  SCHEDULEDDURATION1: 'Schedule Duration:',
  REWARDDURATIONINSECS: 'Duration in Secs of Reward Expiry: ',
  MAXWITHDRAWLIMIT: 'MaxWithdrawLimit: ',
  MINWITHDRAWLIMIT: 'MinWithdrawLimit: ',
  DELETE_QUESTIONS: 'Disable Questions',
  DELETE_QUESTIONS_LIST: 'Disable Question List',
  YES: 'Yes',
  NO: 'No',
  POPUP_DELETE: 'Are you sure you want to disable ',
  DELETE_ALL_QUESTIONS: 'Disable All Questions',
  TOTAL_COUNT: 'Total Questions: ',
  DELETE_SELECTED_QUESTIONS: 'Disable Selected Questions',
  DELETE_TOURNAMENT: 'Delete Tournaments',
  DELETE_ALL_TOURNAMENTS: 'All Tournaments',
  DELETE_SELECTED_TOURNAMENTS: 'Selected Tournaments',
  DELETE_TOURNAMENT_LIST: 'Delete Tournament List',
  POPUP_TOURNAMENT_DELETE: 'Are you sure you want to delete the tournaments?',
  TOURNAMENT_MASTER: 'Tournament',
  DELETE_MASTER_TOURNAMENTS: 'All Tournaments with Master',
  POPUP_MASTER_TOURNAMENT_DELETE: 'Are you sure you want to delete the master and all instances of the tournament?',
  POPUP_QUEST_DELETE: 'questions?',
  DELETE_TEXT: 'The following questions are locked for the following tournaments. Do you still want to mark the questions as disable?',
  QUEST_TEXT: 'Question text: ',
  TOURNAMENT_NAME: 'Tournament name: ',
  DISABLE: 'Disable',
  DISABLED: 'Disabled',
  ENABLE: 'Enable',
  ENABLE_SELECTED_QUESTIONS: 'Enable Selected Questions',
  ENABLE_ALL_QUESTIONS: 'Enable All Questions',
  IOSURL: 'IosUrl: ',
  ANDROIDURL: 'AndroidUrl: ',
  ACTIVITY_FILTER: 'Activity Filter',
  TOURNAMENT_FILTER: 'Tournament Filter',
  ANDROID_BUILD: 'Android Build: ',
  IOS_BUILD: 'Ios Build: ',
  OPTIONONE: 'Option1',
  OPTIONTWO: 'Option2',
  OPTIONTHREE: 'Option3',
  OPTION4: 'Option4',
  ACTIVITY: 'Activity',
  EDIT_UPLOAD_PATH: '/o',
  USER_KYC: 'User KYC',
  PAN_CARD_NUMBER: 'Pan Card Number',
  DATE_OF_BIRTH: 'Date of Birth',
  KYC_DETAILS: 'KYC Details',
  USER_DETAILS: 'User Details',
  TRANSACTION_STATUS: 'Transaction Status: ',
  PENDING_KYC: 'Pending KYC',
  USER_STATUS: 'User Status: ',
  WITHDRAWAL_ALLOWED: 'Withdrawal Allowed',
  SPONSER_ID: 'Sponser Id',
  SPONSER_ID_REQUIRED: 'Sponser id is required',
  REGISTER_NOW: 'Register Now!',
  REGISTER: 'Register',
  FIRST_NAME: 'First Name',
  LAST_NAME: 'Last Name',
  CONTACT_NUMBER: 'Contact Number',
  FIRST_NAME_REQUIRED: 'First name is required',
  LAST_NAME_REQUIRED: 'Last anme is required',
  CONTACT_NO_REQUIRED: 'Contact number is required',
  WELCOME_MESSAGE: 'Welcome to Gyanalaya',
};
