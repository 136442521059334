export const GET_ORDER_REQUESTED = 'payment/GET_ORDER_REQUESTED';
export const GET_ORDER_SUCCESS = 'payment/GET_ORDER_SUCCESS';
export const GET_ORDER_FAILURE = 'payment/GET_ORDER_FAILURE';

export const GET_PAYMENT_REQUESTED = 'payment/GET_PAYMENT_REQUESTED';
export const GET_PAYMENT_SUCCESS = 'payment/GET_PAYMENT_SUCCESS';
export const GET_PAYMENT_FAILURE = 'payment/GET_PAYMENT_FAILURE';

const initialState = {
  loading: false,
  loaded: false,
  error: false,
  errorDesc: [],
  data: {},
  code: '',
  msg: '',
  status: '',
  paymentData: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    
    case GET_ORDER_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_ORDER_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        data: action && action.result,
        code: '',
        status: '200'
      };
    }
    case GET_ORDER_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
        code: action && action.result && action.code,
        msg: action && action.result && action.msg,
        status: action && action.result && action.result.status
      };
    }
    case GET_PAYMENT_REQUESTED:
    {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }
    case GET_PAYMENT_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        paymentData: action && action.result,
        code: '',
        status: '200'
      };
    }
    case GET_PAYMENT_FAILURE:
    {
      return {
        ...state,
        loading: false,
        loaded: false,
        error: true,
        code: action && action.result && action.code,
        msg: action && action.result && action.msg,
        status: action && action.result && action.result.status
      };
    }
    default:
      return state;
  }
};

export const payInOrderAPI = () => {
  return {
    types: [GET_ORDER_REQUESTED, GET_ORDER_SUCCESS, GET_ORDER_FAILURE],
    promise: client => client.get('paymentOrderDetails', {
    })
  };
};

export const paymentTransactions = () => {
  return {
    types: [GET_PAYMENT_REQUESTED, GET_PAYMENT_SUCCESS, GET_PAYMENT_FAILURE],
    promise: client => client.get('paymentTransactions', {
    })
  };
};
