/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useRef, useEffect } from 'react';
import * as FaIcons from 'react-icons/fa';
import { IconContext } from 'react-icons';

import { Link } from 'react-router-dom';
import cn from 'classnames';
import {
  DropdownToggle, DropdownItem, UncontrolledDropdown, DropdownMenu
} from 'reactstrap';
import src from '../../../assets/images/gyan.png';
import EN from '../../../localization/EN';

import { SidebarData } from './SlidebarData';
import './Navbar.css';

export default function Navbar() {
  const [sidebar, setSidebar] = useState(false);

  const showSidebar = () => setSidebar(!sidebar);
  const boxRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (boxRef.current && !boxRef.current.contains(event.target)) {
        setSidebar(false);
      } else {
        setSidebar(true);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [boxRef]);

  return (
    <>
      <IconContext.Provider value={cn('iconColor')}>
        <div className="navbar">
          <div className="row">
            <div ref={boxRef} className="col-auto">
              <Link to="#" className="menu-bars">
                <FaIcons.FaBars onClick={showSidebar} />
              </Link>
            </div>
            <div className="col-auto">
              <a className="navbar-brand" href="#">
                <img
                  src={src}
                  alt="fullimage"
                  className="img-responsive cursor-default main-img mr-10 ml-10 nmt-5"
                />
                <span className="headerText cursor-default text-20px headerColor">
                  {/* {EN.ADMIN_CONSOLE} */}
                </span>
              </a>
            </div>
          </div>
          <UncontrolledDropdown className={cn('dropdown cursor-pointer')}>
            <DropdownToggle tag="span">
              <div className="headerText">
                <i className={cn('fa fa-user-circle')} />
                <span className="span">
                  {EN.ADMIN}
                </span>
                <i className={cn('fa fa-angle-down ml-10')} />
              </div>
            </DropdownToggle>
            <DropdownMenu className="mt-10">
              <DropdownItem>
                <a className="nav-link text-secondary test2" href="/logout">
                  <i className={cn('fa fa-sign-out pr-2')} aria-hidden="true" />
                  {EN.LOGOUT}
                </a>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
        <nav ref={boxRef} className={sidebar ? 'nav-menu active' : 'nav-menu'}>
          <ul className="nav-menu-items" onClick={showSidebar} onKeyDown={showSidebar}>
            {/* <li className="navbar-toggle">
              <Link to="#" className="menu-bars">
                <AiIcons.AiOutlineClose />
              </Link>
            </li> */}

            {SidebarData.map((item) => {
              return (
                <li key={item} className={item.cName}>
                  <Link to={item.path}>
                    {item.icon}
                    <span className="pl-2">
                      {item.title}
                    </span>
                  </Link>
                </li>
              );
            })}
          </ul>
        </nav>
      </IconContext.Provider>
    </>
  );
}
